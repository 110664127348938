/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

h2 {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

h3 {
  color: #373737;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-image {
  width: 100%;
}

a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Header */

.Header-contact {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
  padding-bottom: 5px
}

.Header-address {
  color: #858585;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.Header-menu {
  color: #505050;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Header-menu a {
  color: #505050;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 15px;
}

.Header-mobile-text {
  color: #858585;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Header-logo-size-mobile{
  margin-bottom: 2.5rem;
}

.Header-menu-mobile{
  color: #505050;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.Header-logomobile {
  width: 80px;
}

/* Start */

.Start-item {
  color: #FFF;
  background: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 40px 60px;
}

@media only screen and (max-width: 500px) {
  .Start-item {
    padding: 15px 10px;
    margin-left: -16px;
    text-align: left;
    font-size: 14px;
  }
}

.Start-item-right {
  color: #FFF;
  background: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 40px 30px;
}

@media only screen and (max-width: 500px) {
  .Start-item-right {
    padding: 15px 10px;
    margin-left: -16px;
    text-align: left;
    font-size: 14px;
  }
}

.Start-title-black {
  color: #181818;
  font-family: Montserrat;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  .Start-title-black {
    font-size: 28px;
  }
}

.Start-title-blue {
  color: #233C73;
  font-family: Montserrat Alternates;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  .Start-title-blue {
    font-size: 28px;
  }
}

.Start-subtitle {
  color: #373737;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Start-subtitle {
    font-size: 18px;
    margin-top: 10px;
    text-transform: uppercase;
  }
}

.Start-block {
  height: 700px;
  background-image: url('penoplast-fon.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  margin-top: 2rem;
}

@media only screen and (max-width: 500px) {
  .Start-block {
    background-position: -30% center;
    height: 600px;
    margin-top: 0;
    margin-left: 16px
  }
}

.Start-block-bottom {
  margin-bottom: -50px;
}

@media only screen and (max-width: 500px) {
  .Start-block-bottom {
    margin-bottom: 20px
  }
}

.Start-position {
  position: relative;
}

.Start-plus {
  position: absolute;
  top: -5px;
  left: 50px;
  color: #F2F2F2;
  text-align: center;
  font-family: Inter;
  font-size: 37px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  .Start-plus {
    display: none;
  }
}

.Start-circle {
  position: absolute;
  bottom: 0;
  left: -700px;
  width: 781px;
  height: 776px;
  flex-shrink: 0;
  border-radius: 781px;
  background: #00319C;
  filter: blur(600px);
  transform: translateZ(0);
}

@media only screen and (max-width: 500px) {
  .Start-circle {
    filter: blur(400px);
    transform: translateZ(0);
  }
}

.Start-parallelogram {
  width: 60vw;
  height: 60px;
  border: 1px solid #000;
  transform: skew(-10deg);
}

@media only screen and (max-width: 500px) {
  .Start-parallelogram {
    width: 80vw;
    height: 85px;
  }
}

.Start-button {
  margin-top: 240px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 500px) {
  .Start-button {
    margin-top: 140px;
  }
}

.Start-parallelogram-text {
  position: absolute;
  top: 15px;
  left: calc((100% - 800px)/2);
  color: #233C73;
  font-family: 'ABeeZee', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  .Start-parallelogram-text {
    font-size: 16px;
    left: 75px;
  }
}

@media only screen and (max-width: 500px) {
  .Start-mobile {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 500px) {
  .Start-slogan {
    margin-top: 90px;
  }
}

/* Products */

.Products-title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 21px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 910px) {
  .Products-title {
    height: 80px;
  }
}

.Products-image {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Products-button {
  text-align: center;
}

/* About */

.About-title {
  padding-top: 200px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 500px) {
  .About-title {
    padding-top: 100px;
  }
}

.About-text {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.About-background {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 22%, #F1F1F1 6.54%, rgba(241, 241, 241, 0.00) 107.85%);
}

@media only screen and (max-width: 500px) {
  .About-background {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0));
  }
}

/* Advantages */

.Advantages-grid-one {
  display: grid;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.Advantages-grid-two {
  display: grid;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.Advantages-grid-three {
  display: grid;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.Advantages-description {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Advantages-description {
    font-size: 15px;
  }
}

.Advantages-margin {
  margin-left: 50px;
}

.Advantages-title {
  color: #373737;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Advantages-title {
    font-size: 16px;
    text-transform: uppercase;
  }
}

.Advantages-number {
  position: absolute;
  color: transparent;
  -webkit-text-stroke: 2px #233C7333;
  font-family: K2D;
  font-size: 300px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Advantages-number-mobile {
  color: transparent;
  -webkit-text-stroke: 2px #233C7333;
  font-family: K2D;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Advantages-circle {
  position: absolute;
  right: -300px;
  bottom: -300px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(500px);
  transform: translateZ(0);
}

@media only screen and (max-width: 500px) {
  .Advantages-circle {
    right: 100px;
  }
}

/* Why */

.Why-block {
  border-top: 1px solid #000;
  padding: 40px 0 40px 0;
}

@media only screen and (max-width: 500px) {
  .Why-block {
    background-color: #D9D9D94D;
    border-top: none;
    padding: 10px;
    margin-bottom: 20px
  }
}

.Why-block-bottom {
  border-bottom: 1px solid #000;
  padding: 40px 0 40px 0;
}

@media only screen and (max-width: 500px) {
  .Why-block-bottom {
    border-bottom: none;
    padding: 10px;
  }
}

.Why-title {
  color: #000;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Why-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.Why-description {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Why-description {
    font-size: 15px;
  }
}

.Why-text{
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: italic;
  font-weight: 200;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Why-text {
    position: absolute;
    right: 16px;
    bottom: 5px;
    width: 60%;
    font-size: 18px;
    opacity: 0.7;
    background: #F2F2F2;
    padding: 7px;
  }
}

.Why-grid-one{
  background-image: url('pallet-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 500px) {
  .Why-grid-one {
    margin-left: 16px;
  }
}

.Why-grid-two{
  background-image: url('pallet-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.Why-grid-three{
  background-image: url('pallet-3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.Why-circle-top {
  position: absolute;
  top: -300px;
  left: -300px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(450px);
  transform: translateZ(0);
}

.Why-circle-bottom {
  position: absolute;
  bottom: -300px;
  right: -300px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(450px);
  transform: translateZ(0);
}

@media only screen and (max-width: 500px) {
  .Why-circle-bottom {
    right: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .Why-image {
    width: 60px;
    margin-left: 15px;
  }
}

/* Carousel */

.Carousel-img {
  margin: auto;
  height: 70px;
}

/* Consultation */

.Consultation-background {
  background: #143A8E;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(151, 159, 177, 1);
  -moz-box-shadow: 0px 0px 50px 0px rgba(151, 159, 177, 1);
  box-shadow: 0px 0px 50px 0px rgba(151, 159, 177, 1);
}

.Consultation-title {
  color: #F3F3F3;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 60px;
}

.Consultation-input{
  background-color: white;
}

.Consultation-button {
  background: white;
}

/* Contacts */

.Contacts-title {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2rem;
}

.Contacts-item-text {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.3rem;
}

.Contacts-item-text-bold {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.3rem;
}

/* Footer */

.Footer-background {
  padding: 2.5rem;
  text-align: center;
  background: #484848;
}

@media only screen and (max-width: 500px) {
  .Footer-background {
    padding: 1.5rem;
  }
}

.Footer-logo {
  margin-bottom: 20px;
}

@media only screen and (max-width: 500px) {
  .Footer-logo {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .Footer-img {
    width: 80px;
  }
}

.Footer-copyrights {
  color: #FFF;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 500px) {
  .Footer-copyrights {
    font-size: 12px;
  }
}

.Footer-circle {
  position: absolute;
  bottom: 0;
  left: -600px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(450px);
  transform: translateZ(0);
}

.Page-title {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.Page-subtitle {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.Page-circle-1 {
  position: absolute;
  top: 50px;
  left: -350px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(600px);
  transform: translateZ(0);
}

.Page-circle-2 {
  position: absolute;
  bottom: 50px;
  right: -350px;
  width: 554px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 554px;
  background: #00319C;
  filter: blur(450px);
  transform: translateZ(0);
}
@media only screen and (max-width: 500px) {
  .Page-circle-2 {
    right: 0;
  }
}

.Page-circle-promyishlennyje-tovary {
  position: absolute;
  top: 300px;
  left: -600px;
  width: 781px;
  height: 776px;
  flex-shrink: 0;
  border-radius: 781px;
  background: #00319C;
  filter: blur(550px);
  transform: translateZ(0);
}

.Page-circle-dveri {
  position: absolute;
  bottom: 300px;
  right: 100px;
  width: 781px;
  height: 776px;
  flex-shrink: 0;
  border-radius: 781px;
  background: #0b46c4;
  filter: blur(650px);
  transform: translateZ(0);
}

@media only screen and (max-width: 500px) {
  .Page-circle-dveri-top {
    position: absolute;
    top: 0;
    left: -600px;
    width: 500px;
    height: 776px;
    flex-shrink: 0;
    border-radius: 781px;
    background: #00319C;
    filter: blur(600px);
    transform: translateZ(0);
  }
}

.Page-line-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.Page-text-right {
  position: absolute;
  bottom: 25px;
  right: 25px;
  color: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.Page-line-left {
  position: absolute;
  bottom: 0;
  left: 32px;
}

.Page-text-left {
  position: absolute;
  bottom: 25px;
  left: 57px;
  color: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.Page-line-left-top {
  position: absolute;
  top: 0;
  left: 0;
}

.Page-line-left-top-img {
  margin-bottom: 15px;
}

.Page-text-left-top {
  position: absolute;
  top: 25px;
  left: 25px;
  color: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.Page-img {
  margin-bottom: 32px;
}

.Page-img-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
}

.Page-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 7rem 0;
}

.Page-mobile {
  color: #233C73;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 40px;
}